import { CURRENCY_CONSTANTS } from "../constants";

export const checkStringSanity = (s) => {
  return (
    s !== null && s !== undefined && typeof s === "string" && s.length >= 1
  );
};

export const formatNumberAsCurrency = (num) => {
  return (
    num
      .toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: CURRENCY_CONSTANTS.INR,
      })
      .replace("₹", "") +
    " " +
    CURRENCY_CONSTANTS.INR
  );
};

export const isEmailValid = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const capitalizeWords = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};
