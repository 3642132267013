import { InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import {
  CUSTOM_COLOR_CONSTANTS,
  CUSTOM_SIZING_CONSTANTS,
} from "../../constants";
import { getArrayMapOnId } from "../../utilities/obj_util";
import { formatNumberAsCurrency } from "../../utilities/string_util";

StepperCheckoutComponent.propTypes = {
  parsedEventOrderItemList: PropTypes.array.isRequired,
  eventTicketList: PropTypes.array.isRequired,
  getEventOrderItemSelectedTicketIdCountMap: PropTypes.func.isRequired,
  isRecomputing: PropTypes.bool.isRequired,
  recomputeRunningBill: PropTypes.func.isRequired,
  subtotalAmount: PropTypes.number.isRequired,
  discountAmount: PropTypes.number.isRequired,
  subtotalPostDiscountAmount: PropTypes.number.isRequired,
  processingFee: PropTypes.number.isRequired,
  surgeChargeAmount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  appliedCouponCode: PropTypes.string,
};

export default function StepperCheckoutComponent({
  parsedEventOrderItemList,
  eventTicketList,
  getEventOrderItemSelectedTicketIdCountMap,
  isRecomputing,
  recomputeRunningBill,
  subtotalAmount,
  discountAmount,
  subtotalPostDiscountAmount,
  processingFee,
  surgeChargeAmount,
  totalAmount,
  appliedCouponCode,
}) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const eventTicketIdInstanceMap = getArrayMapOnId(eventTicketList, "id");
  const ticketIdCountMap = getEventOrderItemSelectedTicketIdCountMap(
    parsedEventOrderItemList
  );
  const [couponCodeText, setCouponCodeText] = useState("");

  function attemptApplyCouponCode() {
    recomputeRunningBill(couponCodeText);
  }

  function renderBillBreakdownCard() {
    const uniqueTicketIdList = Object.keys(ticketIdCountMap);
    const isCouponCodeApplied = discountAmount && subtotalPostDiscountAmount;
    const discountPercentage = parseInt(
      (discountAmount / subtotalAmount) * 100
    );

    if (isRecomputing) {
      return (
        <Card>
          <CardContent>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CircularProgress
                style={{
                  marginRight: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
                }}
              />
              <Typography variant="body1">recomputing</Typography>
            </div>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card>
        <CardContent>
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
              marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
            }}
          >
            Bill breakdown
          </Typography>
          {uniqueTicketIdList.map((uniqueTicketId) => (
            <div
              key={uniqueTicketId}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography variant="body2">
                  {eventTicketIdInstanceMap[uniqueTicketId]?.title}
                </Typography>
                <Typography variant="subtitle2" color={primaryColor}>
                  (
                  {formatNumberAsCurrency(
                    eventTicketIdInstanceMap[uniqueTicketId]?.price
                  )}{" "}
                  x {ticketIdCountMap[uniqueTicketId]})
                </Typography>
              </div>

              <Typography variant="body2">
                {formatNumberAsCurrency(
                  ticketIdCountMap[uniqueTicketId] *
                    eventTicketIdInstanceMap[uniqueTicketId]?.price
                )}
              </Typography>
            </div>
          ))}
          <Divider
            style={{
              marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
              marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
            }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              {isCouponCodeApplied ? "tickets total" : "subtotal"}
            </Typography>
            <Typography variant="body2">
              {formatNumberAsCurrency(subtotalAmount)}
            </Typography>
          </div>
          {isCouponCodeApplied ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">
                discount{" "}
                {discountPercentage > 0 && discountPercentage <= 100 && (
                  <span
                    style={{
                      color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.SUCCESS_GREEN,
                    }}
                  >
                    ({discountPercentage}% off)
                  </span>
                )}
              </Typography>
              <Typography
                variant="body2"
                color={CUSTOM_COLOR_CONSTANTS.COLOR_CODE.SUCCESS_GREEN}
              >
                -{formatNumberAsCurrency(discountAmount)}
              </Typography>
            </div>
          ) : null}
          {isCouponCodeApplied ? (
            <Divider
              style={{
                marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
                marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
              }}
            />
          ) : null}
          {isCouponCodeApplied ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">subtotal</Typography>
              <Typography variant="body2">
                <span
                  style={{
                    color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.SUCCESS_GREEN,
                  }}
                >
                  {formatNumberAsCurrency(subtotalPostDiscountAmount)}
                </span>
                &nbsp;&nbsp;
                <s>{formatNumberAsCurrency(subtotalAmount)}</s>
              </Typography>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">processing fee</Typography>
            <Typography variant="body2">
              {formatNumberAsCurrency(processingFee)}
            </Typography>
          </div>
          {surgeChargeAmount > 0 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" alignItems="center">
                  surge charge
                </Typography>
                <Tooltip
                  enterTouchDelay={0}
                  leaveTouchDelay={10000}
                  title="A small surge charge of 3% on the subtotal is levied if tickets for the event are bought within 3 days of the event"
                >
                  <IconButton size="small">
                    <InfoOutlined fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
              <Typography variant="body2">
                {formatNumberAsCurrency(surgeChargeAmount)}
              </Typography>
            </div>
          )}
          <Divider
            style={{
              marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
              marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
            }}
          />{" "}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">grand total</Typography>
            <Typography variant="body2" color={primaryColor}>
              {formatNumberAsCurrency(totalAmount)}
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  }

  function renderCouponCodeCard() {
    return (
      <div style={{ marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN }}>
        <Card>
          <CardContent>
            <Typography
              variant="body1"
              style={{
                fontWeight: "bold",
                marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
              }}
            >
              Coupon code 🛍️
            </Typography>
            {appliedCouponCode ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: 14 }}>
                  {formatNumberAsCurrency(discountAmount)} off ⚡
                </span>
                <Chip
                  color="primary"
                  size="small"
                  label={appliedCouponCode}
                  onDelete={() => {
                    setCouponCodeText("");
                    recomputeRunningBill();
                  }}
                />
              </div>
            ) : (
              <div>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={10} md={10} lg={10}>
                    <TextField
                      fullWidth
                      label={"Have a coupon code?"}
                      size="small"
                      autoCapitalize="true"
                      value={couponCodeText}
                      onChange={(event) =>
                        setCouponCodeText(
                          event.target.value?.toUpperCase().replace("-", "_")
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <Button variant="text" onClick={attemptApplyCouponCode}>
                      APPLY
                    </Button>
                  </Grid>
                </Grid>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div>
      {renderBillBreakdownCard()}
      {renderCouponCodeCard()}
    </div>
  );
}
