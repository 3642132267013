import {
  ExpandMoreOutlined,
  ReportProblem,
  ShareOutlined,
  VerifiedRounded,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { RWebShare } from "react-web-share";
// Local
import {
  CUSTOM_COLOR_CONSTANTS,
  CUSTOM_SIZING_CONSTANTS,
} from "../../constants";
import { formatNumberAsCurrency } from "../../utilities/string_util";

StepperViewEventComponent.propTypes = {
  eventData: PropTypes.object.isRequired,
  eventCreator: PropTypes.object.isRequired,
  eventTicketList: PropTypes.array.isRequired,
};

const EVENT_DESCRIPTION_DISPLAY_LIMIT = 64;

export default function StepperViewEventComponent({
  eventData,
  eventCreator,
  eventTicketList,
}) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const eventStartDate = new Date(eventData?.start_time);
  const eventEndDate = new Date(eventData?.end_time);

  const eventDescription = eventData?.description?.trim();
  return (
    <div>
      <Card>
        <CardHeader
          action={
            <RWebShare
              data={{
                text: `ticketdude: ${eventData?.title} by ${eventCreator?.full_name}`,
                url: window.location.href,
                title: `ticketdude: ${eventData?.title} by ${eventCreator?.full_name}`,
              }}
            >
              <IconButton>
                <ShareOutlined style={{ color: primaryColor }} />
              </IconButton>
            </RWebShare>
          }
          title={eventData?.title?.trim()}
          subheader={
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  marginRight: 4,
                  fontSize: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
                  color: "#424242",
                }}
              >
                from <b>{eventCreator?.full_name?.trim()}</b>
              </Typography>
              {eventCreator?.is_creator_verified ? (
                <VerifiedRounded
                  style={{
                    color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.VERIFIED_BLUE,
                  }}
                />
              ) : (
                <ReportProblem
                  style={{
                    color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.UNVERIFIED_YELLOW,
                  }}
                />
              )}
            </div>
          }
          titleTypographyProps={{ variant: "body1" }}
          subheaderTypographyProps={{ variant: "subtitle1" }}
        />
        <div
          style={{
            paddingBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
          }}
        >
          {eventDescription.length <= EVENT_DESCRIPTION_DISPLAY_LIMIT ? (
            <Typography
              variant="body2"
              whiteSpace="pre-line"
              style={{
                marginLeft: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
                marginRight: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
              }}
            >
              {eventData?.description?.substring(
                0,
                EVENT_DESCRIPTION_DISPLAY_LIMIT
              ) + "..."}
            </Typography>
          ) : (
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Typography variant="body2">
                  tap to toggle event description
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" whiteSpace="pre-line">
                  {eventData?.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
          <Typography
            variant="body1"
            style={{
              marginLeft: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
              marginRight: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
            }}
          >
            <span style={{ color: primaryColor }}>
              {eventStartDate.toDateString()}
            </span>{" "}
            <i>to</i>{" "}
            <span style={{ color: primaryColor }}>
              {eventEndDate.toDateString()}
            </span>
          </Typography>
        </div>
      </Card>

      <div
        style={{ marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 1.5 }}
      />
      <Card>
        <CardContent>
          <Typography variant="h6">listed tickets</Typography>
          <div style={{ marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN }} />
          {eventTicketList.map((eventTicket) => (
            <div
              key={eventTicket?.title}
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2">{eventTicket?.title}</Typography>
              <Typography variant="body1" style={{ color: primaryColor }}>
                {formatNumberAsCurrency(eventTicket?.price)}
              </Typography>
            </div>
          ))}
        </CardContent>
      </Card>

      <div style={{ marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN }} />
    </div>
  );
}
