import { CssBaseline, Snackbar } from "@mui/material";
import { Container } from "@mui/system";

export default function CustomDefaultContainer({
  children,
  onSnackbarClose,
  alertText = null,
}) {
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        flex: 1,
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CssBaseline />

      {children}

      <Snackbar
        open={alertText != null}
        autoHideDuration={4000}
        onClose={onSnackbarClose}
        message={alertText}
      />
    </Container>
  );
}
