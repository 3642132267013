export const ROUTER_PATH_NAMES = {
  SPLASH_PAGE: "/",
  EVENT_ORDER_CHECKOUT_PAGE: "/e/:eventId/",
  POST_PAYMENT_PAGE: "/event_orders/payments/",
};

export const API_CONSTANTS = {
  API_HOST: process.env.REACT_APP_TICKETDUDE_MANAGEMENT_SERVICE_HOST,
};

export const CUSTOM_COLOR_CONSTANTS = {
  COLOR_CODE: {
    BACKGROUND_COLOR: "#F8F4EF",
    DANGER_RED: "#f44336",
    SUCCESS_GREEN: "#8bc34a",
    VERIFIED_BLUE: "#2196f3",
    UNVERIFIED_YELLOW: "#ffc107",
  },
};

export const CURRENCY_CONSTANTS = {
  INR: "INR",
  USD: "USD",
};

export const CUSTOM_SIZING_CONSTANTS = {
  STANDARD_MARGIN: 16,
};

export const EVENT_ORDER_CONSTANTS = {
  PAYMENT_STATUS: {
    SUCCESS: "captured",
    FAILED: "failed",
    PENDING: "created",
  },
};

export const EVENT_PARTICIPANT_BASIC_FIELD_NAMES = [
  "full_name",
  "email",
  "phone",
  "organization",
  "organization_role",
];

export const WEB_BROWSER_LANGUAGE =
  navigator.language || navigator.userLanguage; // en-IN
export const WEB_BROWSER_LOCALE = WEB_BROWSER_LANGUAGE.substring(
  WEB_BROWSER_LANGUAGE.indexOf("-") + 1
); // IN
