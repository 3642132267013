import Lottie from "react-lottie";
import { EastOutlined } from "@mui/icons-material";
import {
  Fab,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getEventInDetailByPublicId } from "../../api/event";
import CustomDefaultContainer from "../../components/custom_default_container";
import LogoComponent from "../../components/logo_component";
import {
  CUSTOM_COLOR_CONSTANTS,
  CUSTOM_SIZING_CONSTANTS,
  EVENT_PARTICIPANT_BASIC_FIELD_NAMES,
  ROUTER_PATH_NAMES,
} from "../../constants";
import StepperFillFormComponent from "./stepper_fill_form_component";
import StepperViewEventComponent from "./stepper_view_event_component";
import * as lottieAnimationData from "../../assets/lottie/ticket_qr.json";
import { checkObjectSanity } from "../../utilities/obj_util";
import { checkArraySanity } from "../../utilities/arr_util";
import StepperCheckoutComponent from "./stepper_checkout_component";
import { formatNumberAsCurrency } from "../../utilities/string_util";
import {
  createNewEventOrder,
  getEventOrderAmountBreakdown,
} from "../../api/event_order";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const stepLabelList = ["view event 🎉", "fill form ✍🏼", "checkout 🚀"];

function getEventOrderItemSelectedTicketIdCountMap(arrWithEventTicketId) {
  const eventTicketIdCountMap = {};
  arrWithEventTicketId.forEach((eventOrderItemObj) => {
    const eventTicketId = eventOrderItemObj?.event_ticket_id;
    if (eventTicketId in eventTicketIdCountMap)
      eventTicketIdCountMap[eventTicketId]++;
    else eventTicketIdCountMap[eventTicketId] = 1;
  });
  return eventTicketIdCountMap;
}

function parseIndividualEventOrderItem(unparsedEventOrderItem) {
  const fieldNamesForEventOrderItem = Object.keys(unparsedEventOrderItem);

  const parsedEventOrderItemObj = {};
  const eventFormResponse = {};
  fieldNamesForEventOrderItem.forEach((fieldName) => {
    const fieldValue = unparsedEventOrderItem[fieldName];
    if (
      EVENT_PARTICIPANT_BASIC_FIELD_NAMES.includes(fieldName) ||
      fieldName === "event_ticket_id"
    ) {
      parsedEventOrderItemObj[fieldName] = fieldValue;
    } else {
      eventFormResponse[fieldName] = fieldValue;
    }
  });
  if (checkObjectSanity(eventFormResponse))
    parsedEventOrderItemObj.event_form_response = eventFormResponse;

  return parsedEventOrderItemObj;
}

export default function EventDetailPage() {
  const navigate = useNavigate();
  const { eventId } = useParams();

  const {
    isLoading,
    data: compositeEventInstance,
    error,
  } = useQuery(
    "get_event_in_detail_by_public_id",
    () => getEventInDetailByPublicId(eventId),
    { retry: 2, refetchOnWindowFocus: false, staleTime: 300000 }
  );

  useEffect(() => {
    if (error) {
      setAlertText(error?.response?.data?.error);
    }
  }, [error]);

  // State
  // UI
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const [alertText, setAlertText] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isRecomputing, setIsRecomputing] = useState(false);

  // Data
  const [eventOrderItemUnparsedList, setEventOrderItemUnparsedList] = useState(
    []
  );
  const [eventOrderItemParsedList, setEventOrderItemParsedList] = useState([]);
  const [subtotalAmount, setSubtotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [subtotalPostDiscountAmount, setSubtotalPostDiscountAmount] =
    useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [surgeChargeAmount, setSurgeChargeAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [appliedCouponCode, setAppliedCouponCode] = useState(null);

  // Data - Post pay button click
  const [customIsLoading, setCustomIsLoading] = useState(false);
  const [
    newEventOrderWithoutTransactionSession,
    setNewEventOrderWithoutTransactionSession,
  ] = useState(null);

  function onFabClick() {
    if (activeStep === 0) setActiveStep(1);
    else if (activeStep === 1) onFillFormComplete();
    else if (activeStep === 2) onPayButtonClick();
  }

  async function recomputeRunningBill(appliedCouponCodeUnverified = null) {
    const eventTicketIdCountMap = getEventOrderItemSelectedTicketIdCountMap(
      eventOrderItemUnparsedList
    );
    if (checkObjectSanity(eventTicketIdCountMap)) {
      try {
        const {
          subtotal_amount,
          discount_amount,
          subtotal_post_discount_amount,
          processing_fee_amount,
          surge_charge_amount,
          final_amount,
        } = await getEventOrderAmountBreakdown(
          eventTicketIdCountMap,
          appliedCouponCodeUnverified
        );
        setSubtotalAmount(subtotal_amount);
        setDiscountAmount(discount_amount);
        setSubtotalPostDiscountAmount(subtotal_post_discount_amount);
        setProcessingFee(processing_fee_amount);
        setSurgeChargeAmount(surge_charge_amount);
        setTotalAmount(final_amount);
        setAppliedCouponCode(appliedCouponCodeUnverified);
      } catch (error) {
        const errorText = error.response.data?.error;
        setAlertText(errorText);
      }
    } else {
      setSubtotalAmount(0);
      setDiscountAmount(0);
      setSubtotalPostDiscountAmount(0);
      setProcessingFee(0);
      setSurgeChargeAmount(0);
      setTotalAmount(0);
    }
    setIsRecomputing(false);
  }

  async function onPayButtonClick() {
    setCustomIsLoading(true);
    const newEventOrderInstance = await createNewEventOrder(
      eventOrderItemParsedList,
      eventId,
      appliedCouponCode
    );
    setNewEventOrderWithoutTransactionSession(
      newEventOrderInstance?.event_order
    );
    // RZP
    const paymentCallbackUrl = `${ROUTER_PATH_NAMES.POST_PAYMENT_PAGE}?public_id=${newEventOrderInstance?.event_order?.public_id}`;
    const paymentAmount = newEventOrderInstance?.event_order?.final_amount;
    if (paymentAmount === 0) {
      // For tickets without registration fee, bypass paywall
      setCustomIsLoading(false);
      navigate(paymentCallbackUrl, { replace: true });
      return;
    }

    const pgOrderId =
      newEventOrderInstance?.event_order?.transaction?.pg_order_id;
    var options = {
      key: process.env.REACT_APP_PG_KEY_ID,
      name: `${process.env.REACT_APP_NAME} ✅`,
      description: `Buy ${eventOrderItemParsedList.length} tickets for ${compositeEventInstance?.event?.title}`,
      image:
        "https://static.wixstatic.com/media/37649e_d91bb6938d924ae3b5df5330f83124e9~mv2.png/v1/fill/w_144,h_144,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/37649e_d91bb6938d924ae3b5df5330f83124e9~mv2.png",
      order_id: pgOrderId,
      remember_customer: true,
      send_sms_hash: true,
      theme: {
        color: primaryColor,
        backdrop_color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.BACKGROUND_COLOR,
      },
      handler: (response) => navigate(paymentCallbackUrl, { replace: true }),
    };
    const razorpayPaymentPageInstance = new window.Razorpay(options);
    razorpayPaymentPageInstance.open();
    setCustomIsLoading(false);
  }

  function onFillFormComplete() {
    // Parse and transform eventOrderItemUnparsedList
    const parsedEventOrderItemList = eventOrderItemUnparsedList.map(
      (unparsedEventOrderItem) =>
        parseIndividualEventOrderItem(unparsedEventOrderItem)
    );
    if (checkArraySanity(parsedEventOrderItemList)) {
      setEventOrderItemParsedList(parsedEventOrderItemList);
      setActiveStep(2);
    }
  }

  function renderStepperComponent() {
    return (
      <Stepper alternativeLabel activeStep={activeStep}>
        {stepLabelList.map((label, index) => (
          <Step key={label}>
            <StepLabel
              onClick={
                checkObjectSanity(newEventOrderWithoutTransactionSession)
                  ? null
                  : () => {
                      if (index < activeStep) setActiveStep(index);
                    }
              }
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  function renderMainContent() {
    if (error)
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%",
          }}
        >
          <Typography variant="h6">
            {error?.response?.data?.error ||
              error?.response?.data ||
              error?.message}
          </Typography>
        </div>
      );

    const { event, event_creator, event_ticket_list, event_form_field_list } =
      compositeEventInstance;
    const eventTicketList = event_ticket_list.sort((a, b) => a?.id - b?.id);

    return (
      <div>
        <div
          style={{
            marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 1.5,
            marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 1.5,
          }}
        >
          {renderStepperComponent()}
        </div>

        {activeStep === 0 && (
          <StepperViewEventComponent
            eventData={event}
            eventCreator={event_creator}
            eventTicketList={eventTicketList}
          />
        )}
        {activeStep === 1 && (
          <StepperFillFormComponent
            eventTicketList={eventTicketList}
            eventFormFieldList={event_form_field_list}
            eventOrderItemUnparsedList={eventOrderItemUnparsedList}
            setEventOrderItemUnparsedList={setEventOrderItemUnparsedList}
            isRecomputing={isRecomputing}
            recomputeRunningBill={recomputeRunningBill}
            subtotalAmount={subtotalAmount}
            discountAmount={discountAmount}
            subtotalPostDiscountAmount={subtotalPostDiscountAmount}
            processingFee={processingFee}
            surgeChargeAmount={surgeChargeAmount}
            totalAmount={totalAmount}
            parseIndividualEventOrderItem={parseIndividualEventOrderItem}
            setAlertText={setAlertText}
          />
        )}
        {activeStep === 2 && checkArraySanity(eventOrderItemParsedList) && (
          <StepperCheckoutComponent
            parsedEventOrderItemList={eventOrderItemParsedList}
            eventTicketList={eventTicketList}
            getEventOrderItemSelectedTicketIdCountMap={
              getEventOrderItemSelectedTicketIdCountMap
            }
            isRecomputing={isRecomputing}
            recomputeRunningBill={recomputeRunningBill}
            subtotalAmount={subtotalAmount}
            discountAmount={discountAmount}
            subtotalPostDiscountAmount={subtotalPostDiscountAmount}
            processingFee={processingFee}
            surgeChargeAmount={surgeChargeAmount}
            totalAmount={totalAmount}
            appliedCouponCode={appliedCouponCode}
            setSubtotalAmount={setSubtotalAmount}
            setProcessingFee={setProcessingFee}
            setTotalAmount={setTotalAmount}
            setAlertText={setAlertText}
          />
        )}

        <Fab
          color="primary"
          variant="extended"
          style={{
            right: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
            bottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
            top: "auto",
            left: "auto",
            position: "fixed",
          }}
          onClick={
            checkObjectSanity(newEventOrderWithoutTransactionSession)
              ? null
              : onFabClick
          }
        >
          {activeStep === 0 && "fill form"}
          {activeStep === 1 && "checkout"}
          {activeStep === 2 && `pay ${formatNumberAsCurrency(totalAmount)}`}

          <EastOutlined sx={{ ml: 1 }} />
        </Fab>
      </div>
    );
  }

  return (
    <CustomDefaultContainer
      onSnackbarClose={() => setAlertText(null)}
      alertText={alertText}
    >
      <main
        style={{ paddingBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 6 }}
      >
        <div
          style={{
            display: "inline",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LogoComponent style={{ float: "left" }} />
          <Lottie
            style={{ float: "right" }}
            options={lottieOptions}
            height={CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 1.8}
            width={CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 5}
          />
        </div>
        <br />
        <br />
        {(isLoading || customIsLoading) && <LinearProgress />}
        {!(isLoading || customIsLoading) && renderMainContent()}
        {/* <div id="paymentForm" style={{ height: "100%" }} /> */}
      </main>
    </CustomDefaultContainer>
  );
}
