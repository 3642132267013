export function getArrayMapOnId(objectList, idFieldName) {
  const obj = {};
  objectList.forEach((element) => {
    obj[element[idFieldName]] = element;
  });
  return obj;
}

export function checkObjectSanity(obj) {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj === "object" &&
    Object.keys(obj).length > 0
  );
}
