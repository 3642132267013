import axios from "axios";
import { checkStringSanity } from "../utilities/string_util";

const apiSubPath = "event_orders";

export const getEventOrderPaymentStatus = async (eventOrderPublicId) => {
  const apiResponse = await axios.get(
    `/${apiSubPath}/get_event_order_by_public_id/`,
    {
      params: {
        event_order_public_id: eventOrderPublicId,
      },
    }
  );
  return apiResponse.data;
};

export const getEventOrderAmountBreakdown = async (
  ticketIdCountMap,
  appliedCouponCode
) => {
  const requestBody = {
    event_ticket_id_count_map: ticketIdCountMap,
  };
  if (appliedCouponCode) requestBody.coupon_code = appliedCouponCode;

  const apiResponse = await axios.post(
    `/${apiSubPath}/get_event_order_amount_breakdown/`,
    requestBody
  );
  return apiResponse.data;
};

export const createNewEventOrder = async (
  parsedEventOrderItemList,
  eventId,
  couponCode
) => {
  const requestBody = {
    event_id: eventId,
    event_order_item_list: parsedEventOrderItemList,
  };
  if (checkStringSanity(couponCode)) requestBody.coupon_code = couponCode;
  const apiResponse = await axios.post(
    `/${apiSubPath}/create_inactive_event_order/`,
    requestBody
  );
  return apiResponse.data;
};
