import PropTypes from "prop-types";
import { DeleteOutlined } from "@mui/icons-material";
import { Avatar, Grid, IconButton, Typography, useTheme } from "@mui/material";

CustomListTileComponent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onDeleteIconPressed: PropTypes.func.isRequired,
};

export default function CustomListTileComponent({
  title,
  subtitle,
  onDeleteIconPressed,
}) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <Grid container height={80} spacing={6}>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <Avatar sx={{ bgcolor: primaryColor }}>
          {title?.charAt(0)?.toUpperCase()}
        </Avatar>
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8}>
        <div>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </div>
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <IconButton onClick={onDeleteIconPressed}>
          <DeleteOutlined style={{ color: "red" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
