import axios from "axios";

const apiSubPath = "events";

export const getEventInDetailByPublicId = async (eventId) => {
  const apiResponse = await axios.get(
    `/${apiSubPath}/get_event_in_detail_by_public_id/`,
    {
      params: {
        event_id: eventId,
      },
    }
  );
  return apiResponse.data;
};
