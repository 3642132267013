import {
  AddOutlined,
  CloseOutlined,
  EastOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  Fab,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Slide,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import CustomListTileComponent from "../../components/custom_list_tile_component";
import {
  CUSTOM_COLOR_CONSTANTS,
  CUSTOM_SIZING_CONSTANTS,
} from "../../constants";
import { getArrayMapOnId } from "../../utilities/obj_util";
import {
  capitalizeWords,
  checkStringSanity,
  formatNumberAsCurrency,
  isEmailValid,
} from "../../utilities/string_util";
import { validateEventOrderItemParsed } from "../../api/event_order_item";

StepperFillFormComponent.propTypes = {
  eventTicketList: PropTypes.array.isRequired,
  eventFormFieldList: PropTypes.array.isRequired,
  eventOrderItemUnparsedList: PropTypes.array.isRequired,
  setEventOrderItemUnparsedList: PropTypes.func.isRequired,
  isRecomputing: PropTypes.bool.isRequired,
  recomputeRunningBill: PropTypes.func.isRequired,
  subtotalAmount: PropTypes.number.isRequired,
  processingFee: PropTypes.number.isRequired,
  surgeChargeAmount: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  parseIndividualEventOrderItem: PropTypes.func.isRequired,
  setAlertText: PropTypes.func.isRequired,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StepperFillFormComponent({
  eventTicketList,
  eventFormFieldList,
  eventOrderItemUnparsedList,
  setEventOrderItemUnparsedList,
  isRecomputing,
  recomputeRunningBill,
  subtotalAmount,
  processingFee,
  surgeChargeAmount,
  totalAmount,
  parseIndividualEventOrderItem,
  setAlertText,
}) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const eventFormFieldMap = getArrayMapOnId(eventFormFieldList, "field_name");

  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [newEventOrderItemUnparsed, setNewEventOrderItemUnparsed] =
    useState(null);
  const [errorText, setErrorText] = useState(null);
  const [errorFormFieldName, setErrorFormFieldName] = useState(null);

  useEffect(() => {
    recomputeRunningBill();
  }, [recomputeRunningBill]);

  function computeNewDefaultEventOrderItem(eventTicketInstance) {
    const eventOrderItemObj = {};
    eventFormFieldList.forEach((eventFormFieldObj) => {
      const fieldName = eventFormFieldObj?.field_name;
      if (
        eventFormFieldObj?.event_ticket_title === null ||
        eventFormFieldObj?.event_ticket_title === eventTicketInstance?.title
      ) {
        // To show only form specific / general fields only (ex: IEEE membership no.)
        eventOrderItemObj[fieldName] = "";
      }
    });
    eventOrderItemObj["event_ticket_id"] = eventTicketInstance?.id;
    return eventOrderItemObj;
  }

  function resetFormErrors() {
    setErrorFormFieldName(null);
    setErrorText(null);
  }

  function renderAddTicketDialog() {
    const onNewEventOrderItemUnparsedAdded = () => {
      setEventOrderItemUnparsedList([
        ...eventOrderItemUnparsedList,
        newEventOrderItemUnparsed,
      ]);
      handleClose();
    };

    const handleClose = () => {
      setIsDialogOpen(false);
      setSelectedTicket(null);
      setNewEventOrderItemUnparsed(null);
      resetFormErrors();

      document
        .querySelector("meta[name='theme-color']")
        .setAttribute(
          "content",
          CUSTOM_COLOR_CONSTANTS.COLOR_CODE.BACKGROUND_COLOR
        );
    };

    let eventFormFieldsForSelectedTicket = [];
    if (selectedTicket && eventFormFieldList?.length > 0) {
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", primaryColor);
      eventFormFieldsForSelectedTicket = eventFormFieldList.filter(
        (eventFormField) =>
          eventFormField.event_ticket_title === null ||
          eventFormField.event_ticket_title?.includes(selectedTicket.title)
      );
      const nameField = eventFormFieldList.find(
        (formField) => formField?.field_name === "full_name"
      );
      const emailField = eventFormFieldList.find(
        (formField) => formField?.field_name === "email"
      );
      const phoneField = eventFormFieldList.find(
        (formField) => formField?.field_name === "phone"
      );
      eventFormFieldsForSelectedTicket =
        eventFormFieldsForSelectedTicket.filter(
          (formField) =>
            !(
              formField?.field_name === "full_name" ||
              formField?.field_name === "email" ||
              formField?.field_name === "phone"
            )
        );
      eventFormFieldsForSelectedTicket = [
        nameField,
        emailField,
        phoneField,
        ...eventFormFieldsForSelectedTicket,
      ];
    }

    async function handleNewEventOrderItemFormSubmit(submitEvent) {
      submitEvent.preventDefault();
      setIsDialogLoading(true);
      const existingEmailList = eventOrderItemUnparsedList.map(
        (eventOrderItemUnparsed) => eventOrderItemUnparsed?.email
      );
      let isNewEntryValid = Object.keys(newEventOrderItemUnparsed).every(
        (fieldName) => {
          if (fieldName === "event_ticket_id") {
            return true;
          }

          const selectedEventFormFieldObj = eventFormFieldMap[fieldName];
          const fieldValue = newEventOrderItemUnparsed[fieldName]?.trim();

          // Required field check
          if (
            selectedEventFormFieldObj?.field_required &&
            !checkStringSanity(fieldValue)
          ) {
            setErrorFormFieldName(fieldName);
            setErrorText(
              `${selectedEventFormFieldObj?.field_display_name} is a required field`
            );
            return false;
          }

          // Field max length check
          if (
            checkStringSanity(fieldValue) &&
            fieldValue.length > selectedEventFormFieldObj?.field_max_length
          ) {
            setErrorFormFieldName(fieldName);
            setErrorText(
              `${selectedEventFormFieldObj?.field_display_name} should only be of length ${selectedEventFormFieldObj?.field_max_length} at max`
            );
            return false;
          }

          // Field specific check: `email`
          if (
            fieldName === "email" &&
            checkStringSanity(fieldValue) &&
            !isEmailValid(fieldValue)
          ) {
            setErrorFormFieldName(fieldName);
            setErrorText(`${fieldValue} is not a valid email`);
            return false;
          }

          // Field specific check: `email`
          if (
            fieldName === "email" &&
            checkStringSanity(fieldValue) &&
            isEmailValid(fieldValue) &&
            existingEmailList.includes(fieldValue)
          ) {
            setErrorFormFieldName(fieldName);
            setErrorText(`${fieldValue} has already been added`);
            return false;
          }

          return true; // Passed all validations
        }
      );

      const parsedEventOrderItem = parseIndividualEventOrderItem(
        newEventOrderItemUnparsed
      );
      try {
        await validateEventOrderItemParsed(parsedEventOrderItem);
        if (isNewEntryValid) onNewEventOrderItemUnparsedAdded(); // Success scenario
      } catch (error) {
        const errorText = await error.response.data?.error;
        setAlertText(errorText);
      } finally {
        setIsDialogLoading(false);
      }
    }

    return (
      selectedTicket &&
      eventFormFieldsForSelectedTicket?.length > 0 && (
        <div>
          <Dialog
            fullScreen
            open={isDialogOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseOutlined />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1, color: "white" }}
                  variant="h6"
                  component="div"
                >
                  add participant
                </Typography>
              </Toolbar>
            </AppBar>

            <form onSubmit={handleNewEventOrderItemFormSubmit}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="ticket type"
                    secondary={`${
                      selectedTicket.title
                    } (${formatNumberAsCurrency(selectedTicket.price)})`}
                  />
                </ListItem>

                {eventFormFieldsForSelectedTicket.map((eventFormField) => (
                  <ListItem key={eventFormField?.id}>
                    <TextField
                      fullWidth
                      label={eventFormField?.field_display_name}
                      title={eventFormField?.field_display_name}
                      required={
                        eventFormField.field_name === "full_name" ||
                        eventFormField.field_name === "email" ||
                        eventFormField.field_name === "phone" ||
                        eventFormField.field_required
                      }
                      autoComplete="true"
                      autoCorrect="true"
                      inputMode={
                        eventFormField?.field_display_name?.includes("phone") ||
                        eventFormField?.field_data_type?.includes("NUMBER")
                          ? "numeric"
                          : "text"
                      }
                      type={
                        eventFormField?.field_display_name?.includes("phone") ||
                        eventFormField?.field_data_type?.includes("NUMBER")
                          ? "number"
                          : "text"
                      }
                      inputProps={{
                        style: {
                          textTransform:
                            eventFormField?.field_display_name?.includes("name")
                              ? "capitalize"
                              : "none",
                        },
                        maxLength: eventFormField?.field_display_name?.includes(
                          "phone"
                        )
                          ? null
                          : eventFormField?.field_max_length,
                        max: eventFormField?.field_display_name?.includes(
                          "phone"
                        )
                          ? 9999999999
                          : null,
                      }}
                      error={errorFormFieldName === eventFormField?.field_name}
                      helperText={
                        errorFormFieldName === eventFormField?.field_name
                          ? errorText
                          : null
                      }
                      value={
                        newEventOrderItemUnparsed[eventFormField?.field_name]
                      }
                      onChange={(onChangeEvent) => {
                        const fieldName = eventFormField?.field_name;
                        let newValue = onChangeEvent.target.value;
                        const fieldMaxLength = eventFormField?.field_max_length;

                        if (fieldName === "email")
                          newValue = newValue.toLowerCase();
                        else newValue = capitalizeWords(newValue);

                        if (newValue.length <= fieldMaxLength) {
                          const currentEventOrderItem = {
                            ...newEventOrderItemUnparsed,
                          };
                          currentEventOrderItem[fieldName] = newValue;
                          setNewEventOrderItemUnparsed(currentEventOrderItem);

                          if (
                            errorFormFieldName === fieldName &&
                            checkStringSanity(errorText)
                          ) {
                            resetFormErrors();
                          }
                        } else {
                          setErrorText(
                            `${eventFormField?.field_display_name} can only be of length ${fieldMaxLength} at max`
                          );
                          setErrorFormFieldName(fieldName);
                        }
                      }}
                      onFocus={() => {
                        if (errorFormFieldName !== eventFormField?.field_name) {
                          resetFormErrors();
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>

              <Fab
                type="submit"
                color="primary"
                variant="extended"
                style={{
                  right: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
                  bottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
                  top: "auto",
                  left: "auto",
                  position: "fixed",
                }}
              >
                continue
                <EastOutlined sx={{ ml: 1 }} />
              </Fab>
            </form>

            {isDialogLoading && (
              <LinearProgress
                style={{
                  position: "absolute",
                  bottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
                  left: 0,
                  right: 0,
                }}
              />
            )}
          </Dialog>
        </div>
      )
    );
  }

  return (
    <div>
      <Card>
        <CardContent>
          {isRecomputing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CircularProgress
                style={{
                  marginRight: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
                }}
              />
              <Typography variant="body1">recomputing</Typography>
            </div>
          ) : (
            <div>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                running bill*
              </Typography>
              <div
                style={{
                  marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">subtotal</Typography>
                <Typography variant="body2" style={{ color: primaryColor }}>
                  {formatNumberAsCurrency(subtotalAmount)}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">processing fee</Typography>
                <Typography variant="body2" style={{ color: primaryColor }}>
                  {formatNumberAsCurrency(processingFee)}
                </Typography>
              </div>
              {surgeChargeAmount > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" alignItems="center">
                      surge charge
                    </Typography>
                    <Tooltip
                      enterTouchDelay={0}
                      leaveTouchDelay={10000}
                      title="A small surge charge of 3% on the subtotal is levied if tickets for the event are bought within 3 days of the event"
                    >
                      <IconButton size="small">
                        <InfoOutlined fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Typography variant="body2" style={{ color: primaryColor }}>
                    {formatNumberAsCurrency(surgeChargeAmount)}
                  </Typography>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">final amount</Typography>
                <Typography
                  variant="body2"
                  style={{ color: primaryColor, fontWeight: "bold" }}
                >
                  {formatNumberAsCurrency(totalAmount)}
                </Typography>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <Typography
        variant="caption"
        style={{
          color: primaryColor,
        }}
      >
        (*the first participant added will be mailed the invoice)
      </Typography>

      <div style={{ marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN }}>
        {eventTicketList.map((eventTicket) => {
          const eventOrderItemsForTicket = eventOrderItemUnparsedList.filter(
            (eventOrderItemUnparsed) =>
              eventOrderItemUnparsed?.event_ticket_id === eventTicket?.id
          );

          return (
            <Card
              key={eventTicket?.id}
              style={{
                marginBottom: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN,
              }}
            >
              <CardHeader
                title={
                  <div>
                    <b>{eventTicket?.title}</b>
                    <br />
                    <span style={{ fontSize: 14 }}>
                      ({formatNumberAsCurrency(eventTicket?.price)})
                    </span>
                  </div>
                }
                action={
                  <Button
                    style={{
                      marginTop: CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN / 2,
                    }}
                    variant="outlined"
                    startIcon={<AddOutlined />}
                    onClick={() => {
                      resetFormErrors();
                      setNewEventOrderItemUnparsed(
                        computeNewDefaultEventOrderItem(eventTicket)
                      );
                      setSelectedTicket(eventTicket);
                      setIsDialogOpen(true);
                    }}
                  >
                    add
                  </Button>
                }
                titleTypographyProps={{ variant: "body1" }}
              />
              {eventOrderItemsForTicket.length > 0 && (
                <CardContent>
                  {eventOrderItemsForTicket.map((eventOrderItemUnparsed) => (
                    <div
                      key={eventOrderItemUnparsed?.email}
                      style={{
                        marginBottom:
                          CUSTOM_SIZING_CONSTANTS.STANDARD_MARGIN * 1.5,
                      }}
                    >
                      <CustomListTileComponent
                        title={eventOrderItemUnparsed?.full_name}
                        subtitle={eventOrderItemUnparsed?.email}
                        onDeleteIconPressed={() => {
                          const selectedEventOrderItemIndex =
                            eventOrderItemUnparsedList.findIndex(
                              (element) =>
                                element.email ===
                                  eventOrderItemUnparsed.email &&
                                element.event_ticket_id ===
                                  eventOrderItemUnparsed.event_ticket_id
                            );
                          const updatedEventOrderItemList = [
                            ...eventOrderItemUnparsedList,
                          ];
                          updatedEventOrderItemList.splice(
                            selectedEventOrderItemIndex,
                            1
                          );
                          setEventOrderItemUnparsedList(
                            updatedEventOrderItemList
                          );
                        }}
                      />
                    </div>
                  ))}
                </CardContent>
              )}
            </Card>
          );
        })}
      </div>

      {renderAddTicketDialog()}
    </div>
  );
}
