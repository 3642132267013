import { useEffect } from "react";
import CustomDefaultContainer from "../../components/custom_default_container";
import LogoComponent from "../../components/logo_component";

export default function SplashPage() {
  useEffect(() => {
    window.location.replace("https://www.ticketdude.app/");
  }, []);

  return (
    <CustomDefaultContainer>
      <LogoComponent />
    </CustomDefaultContainer>
  );
}
