import { Typography } from "@mui/material";
import React from "react";

export default function LogoComponent({ fontSize = 32 }) {
  return (
    <span>
      <Typography style={{ display: "inline-block", float: "left", fontSize }}>
        ticket
      </Typography>
      <Typography
        color="primary"
        style={{
          display: "inline-block",
          float: "left",
          fontSize,
        }}
      >
        dude.
      </Typography>
    </span>
  );
}
