import axios from "axios";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ROUTER_PATH_NAMES } from "./constants";
import EventDetailPage from "./pages/event_detail_page";
import PostPaymentPage from "./pages/post_payment_page";
import SplashPage from "./pages/splash_page";

axios.defaults.baseURL =
  process.env.REACT_APP_TICKETDUDE_MANAGEMENT_SERVICE_HOST;

const router = createBrowserRouter([
  {
    path: ROUTER_PATH_NAMES.SPLASH_PAGE,
    element: <SplashPage />,
    index: true,
  },
  {
    path: ROUTER_PATH_NAMES.EVENT_ORDER_CHECKOUT_PAGE,
    element: <EventDetailPage />,
  },
  {
    path: ROUTER_PATH_NAMES.POST_PAYMENT_PAGE,
    element: <PostPaymentPage />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
