import { createTheme } from "@mui/material";
import { CUSTOM_COLOR_CONSTANTS } from "../constants";

export const customTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.BACKGROUND_COLOR,
    },
    primary: {
      main: "#651fff",
    },
    secondary: {
      main: "#FFEB3B",
    },
  },
  typography: {
    fontFamily: "Poppins",
    allVariants: {
      color: "#212121",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});
