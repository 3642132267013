import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
// Local
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { CircularProgress, LinearProgress, useTheme } from "@mui/material";
import { getEventOrderPaymentStatus } from "../../api/event_order";
import CustomDefaultContainer from "../../components/custom_default_container";
import LogoComponent from "../../components/logo_component";
import { CUSTOM_COLOR_CONSTANTS, EVENT_ORDER_CONSTANTS } from "../../constants";
import { checkStringSanity } from "../../utilities/string_util";

export default function PostPaymentPage() {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  const [searchParams] = useSearchParams();
  // Query
  const { isLoading, data: prunedEventOrderWithPaymentStatus } = useQuery(
    "get_event_order_payment_status",
    () => getEventOrderPaymentStatus(searchParams.get("public_id")),
    {
      refetchInterval: (responseData) =>
        checkStringSanity(
          responseData?.event_order?.transaction?.pg_payment_status
        )
          ? false
          : 1001,
    }
  );

  function renderVerifyingPaymentStatusComponent() {
    const orderPublicId =
      prunedEventOrderWithPaymentStatus?.event_order?.public_id;
    return (
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <br />
        Verifying payment status.
        <br />
        Please wait.
        <br />
        <br />
        <b>Order ID</b>
        <br />
        {orderPublicId}
      </div>
    );
  }

  function renderPaymentStatusSuccessComponent() {
    const orderPublicId =
      prunedEventOrderWithPaymentStatus?.event_order?.public_id;
    const ticketCount =
      prunedEventOrderWithPaymentStatus?.event_order_item_list?.length;
    const primaryContact = prunedEventOrderWithPaymentStatus?.primary_contact;
    return (
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleOutline
          style={{
            fontSize: 128,
            color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.SUCCESS_GREEN,
          }}
        />
        <br />
        Hey {primaryContact?.full_name}!
        <br />
        Your order of <b>{ticketCount} ticket(s)</b> is confirmed 🚀
        <br />
        <br />
        Order ID: <span style={{ color: primaryColor }}>{orderPublicId}</span>
        <br />
        <br />
        Please check your mail. We've sent an email to all the folks mentioned
        in the order with further instructions and a QR code that can be used to
        check you into the event.
        <br />
        <br />
        (We've also sent a separate mail only to{" "}
        <i style={{ color: primaryColor }}>{primaryContact?.email}</i> that
        contains an invoice for this transaction)
        <br />
        <br />
        <br />
        <br />
        <div style={{ fontSize: 14 }}>
          For any queries related to payments, please reach out to &nbsp;
          <a
            href="mailto:payments@ticketdude.app"
            target="_blank"
            rel="noreferrer"
          >
            payments@ticketdude.app
          </a>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }

  function renderPaymentStatusFailedComponent() {
    return (
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CancelOutlined
          style={{
            fontSize: 128,
            color: CUSTOM_COLOR_CONSTANTS.COLOR_CODE.DANGER_RED,
          }}
        />
        <br />
        Transaction failed. No money has been debited from your end. Please try
        again.
        <br />
      </div>
    );
  }

  function renderMainContent() {
    const orderPaymentStatus =
      prunedEventOrderWithPaymentStatus?.event_order?.transaction
        ?.pg_payment_status;
    return (
      <div
        style={{
          flex: 1,
          textAlign: "center",
          marginTop: window.innerHeight / 10,
        }}
      >
        {orderPaymentStatus === EVENT_ORDER_CONSTANTS.PAYMENT_STATUS.SUCCESS &&
          renderPaymentStatusSuccessComponent()}
        {orderPaymentStatus === EVENT_ORDER_CONSTANTS.PAYMENT_STATUS.FAILED &&
          renderPaymentStatusFailedComponent()}
        {(orderPaymentStatus === EVENT_ORDER_CONSTANTS.PAYMENT_STATUS.PENDING ||
          orderPaymentStatus === null) &&
          renderVerifyingPaymentStatusComponent()}
      </div>
    );
  }

  return (
    <CustomDefaultContainer>
      <main>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LogoComponent />
        </div>

        {isLoading ? <LinearProgress /> : renderMainContent()}
      </main>
    </CustomDefaultContainer>
  );
}
