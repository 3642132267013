import axios from "axios";

const apiSubPath = "event_order_items";

export const validateEventOrderItemParsed = async (eventOrderItemParsed) => {
  const apiResponse = await axios.post(
    `/${apiSubPath}/validate_event_order_item/`,
    eventOrderItemParsed
  );
  return apiResponse.data;
};
